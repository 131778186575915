import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Link,
  //    NavLink, useHistory, useLocation
} from "react-router-dom";
//import Swal from "sweetalert2";
import "../css/landing.css";
import { Collapse } from "react-bootstrap";
//import Rightcontainer from "../Components/Rightcontainer";
import Downloadbutton from "../Components/Downloadbutton";
import Header from "../Components/Header";

export default function Landing() {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  var baseUrl;
  if (nodeMode === "development") {
    baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }
  const [open, setOpen] = useState(false);
  const [userAllData, setUserAllData] = useState();
  const [WebSitesettings, setWebsiteSettings] = useState("");
  const fetchData = async () => {
    const response = await fetch(baseUrl + "settings/data");
    const data = await response.json();
    return setWebsiteSettings(data);
  };

  const role = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    await axios
      .get(baseUrl + `me`, { headers })
      .then((res) => {
        setUserAllData(res.data);
      })
      .catch((e) => {
        if (e.response?.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          //window.location.href = "/login";
          //window.location.reload()
          //history.push("/login")
        }
      });
  };

  useEffect(() => {
    let access_token = localStorage.getItem("token");
    access_token = localStorage.getItem("token");
    if (!access_token) {
      //window.location.reload()
    }
    role();
    fetchData();
  }, []);

  return (
    <>
     
    
      <div className="leftContainer">
      
        <div className="main-area" style={{ paddingTop: "65px" }}>
        
        <center>
        <div className="header_top_message">
       
        <span className="message">Commission: 5% ◉ Referral: 3% For All Games No TDS,No GST</span>
        </div>
         </center>
<br>
</br>
          <section className="games-section p-3">
           
            <span className="d-none text-dark d-block text-left">
              <b>Our Games</b>
            </span>
            
            
           
           <br>
           </br>
           
           
              <div className="games-window ">
                <Link
                  className="gameCard-container"
                  to={`/Homepage/Ludo%20Classics%20Lite`}
                >
                  <span className="d-none blink text-primary d-block text-right">
                    ◉ Ludo Classic Only (LIVE)
                  </span>
                  <picture className="gameCard-image">
                    <img
                      width="100%"
                      src={
                        process.env.PUBLIC_URL +
                        "/Images/LandingPage_img/50-500.jpg"
                      }
                      alt=""
                    />
                  </picture>
                  <div className="gameCard-title">
                    <span className="d-none text-dark d-block text-right">
                      ◉ LUDO CLASSIC
                    </span>
                  </div>
                  <div className="goverlay">
                    <div className="text">Comming Soon</div>
                  </div>
                </Link>
                {/*/Homepage/Ludo%20Classics%20Pro*/}
                <Link
                  className="gameCard-container"
                  to={`/Homepage/Ludo%20Classics%20Pro`}
                >
                  <span className="d-none blink text-primary d-block text-right">
                    ◉ Ludo Classic Only (LIVE)
                  </span>
                  <picture className="gameCard-image">
                    <img
                      width="100%"
                      src={
                        process.env.PUBLIC_URL +
                        "/Images/LandingPage_img/550-50000.jpg"
                      }
                      alt=""
                    />
                  </picture>
                  <div className="gameCard-title">
                    <span className="d-none text-dark d-block text-right">
                      ◉  LUDO CLASSIC
                    </span>
                  </div>
                  <div className="goverlay">
                    <div className="text">Comming Soon</div>
                  </div>
                </Link>




                {/* <Link className="gameCard-container" to={`/Homepage/Ludo%20Popular`}> */}
                {/*
                <Link className="gameCard-container" to={``}>
                  <span className="d-none blink text-danger d-block text-right">
                    ◉ COMING SOON
                  </span>
                  <picture className="gameCard-image">
                    <img
                      width="100%"
                      src={
                        process.env.PUBLIC_URL +
                        "/Images/LandingPage_img/secondLudo.webp"
                      }
                      alt=""
                    />
                  </picture>
                  <div className="gameCard-title">
                    <span className="d-none text-dark  d-block text-right">
                      ◉ POPULAR LUDO
                    </span>
                  </div>
                  <div className="goverlay">
                    <div className="text">Comming Soon</div>
                  </div>
                </Link>
*/}
                {/* <Link className="gameCard-container" to={`/Homepage/Ludo%201%20Goti`}> */}
                {/* <Link className="gameCard-container" to={``}>
                                        <span className="d-none blink text-danger d-block text-right">◉ LIVE</span>
                                        <picture className="gameCard-image">
                                            <img width="100%" src={process.env.PUBLIC_URL + '/Images/LandingPage_img/thirdLudo.webp'} alt="" />
                                        </picture>
                                       
                    <div className="gameCard-title">
                                            <span className="d-none blink text-success d-block text-right">◉ Ludo no cut </span>

                                        </div>
                                        <div className="goverlay">
                                            <div className="text">Comming Soon</div>
                                        </div>
                                    </Link> */}

                {/* <Link className="gameCard-container" to={`/Homepage/Ludo%20Ulta`} > */}
                {/* <Link className="gameCard-container" to={``} >
                                        <span className="d-none blink text-danger d-block text-right">◉ LIVE</span>
                                        <picture className="gameCard-image">
                                            <img width="100%" src={process.env.PUBLIC_URL + '/Images/LandingPage_img/fourthLudo.webp'} alt="" />

                                        </picture>
                                        <div className="gameCard-title">
                                            <span className="d-none blink text-success d-block text-right">◉ Ludo Ulta </span>

                                        </div>

                                        <div className="goverlay">
                                            <div className="text">Comming Soon</div>
                                        </div>

                                    </Link> */}
              </div>
             
            
             
          </section>
          
          <section className="footer">
            <div className="footer-divider" />
            <div className="footer-2">
            <a
              className="px-3 py-4 d-flex align-items-center"
              href="#!"
              style={{ textDecoration: "none" }}
              onClick={() => setOpen(!open)}
              aria-controls="example-collapse-text"
              aria-expanded={open}
            >
              <picture className="icon">
                <img
                  src="/Images/LandingPage_img/hiplayicon.png"
                  width="56px"
                  height="56px"
                  alt="profile"
                  style={{ width: "125px", height: "40px" }}
                />
              </picture>
              <span
                style={{
                  color: "#050505",
                  fontSize: "1em",
                  fontWeight: 400,
                }}
                className={!open ? "d-block" : "d-none"}
              >
                {" "}
               
              </span>

              {open ? (
                <i
                  className=""
                  style={{ fontSize: "1.7em", color: "rgb(103, 103, 103)" }}
                ></i>
              ) : (
                <i
                  style={{ fontSize: "1.7em", color: "rgb(103, 103, 103)" }}
                  className=""
                ></i>
              )}
            </a>
            
              <div id="example-collapse-text" className="px-3 overflow-hidden">
                <div className="row footer-links">
                  <Link className="col-6" to="/term-condition">
                    Terms &amp; Condition
                  </Link>
                  <Link className="col-6" to="/PrivacyPolicy">
                    Privacy Policy
                  </Link>
                  <Link className="col-6" to="/RefundPolicy">
                    Refund/Cancellation Policy
                  </Link>
                  <Link className="col-6" to="/contact-us">
                    Contact Us
                  </Link>
                  <Link className="col-6" to="/responsible-gaming">
                    Responsible Gaming
                  </Link>
                </div>
              </div>
            
            <hr></hr>
            
            <div className="footer-divider" />
            <div className="px-3 py-4">
              <div className="footer-text-bold">About Us</div>
              <br />
              <div className="footer-text">
                {WebSitesettings ? WebSitesettings.WebsiteName : "KingAdda"} is a
                real-money gaming product owned and operated by{" "}
                {WebSitesettings ? WebSitesettings.CompanyName : " "} ("
                {WebSitesettings ? WebSitesettings.WebsiteName : " "}" or "We"
                or "Us" or "Our").
              </div>
              <br />
              <div className="footer-text-bold">
                Our Business &amp; Products
              </div>
              <br />
              <div className="footer-text">
                We are an HTML5 game-publishing company and our mission is to
                make accessing games fast and easy by removing the friction of
                app-installs.
              </div>
              <br />
              <div className="footer-text">
                {WebSitesettings ? WebSitesettings.WebsiteName : " "} is a
                skill-based real-money gaming platform accessible only for our
                users in India. It is accessible on{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={WebSitesettings ? WebSitesettings.CompanyWebsite : " "}
                >
                  {WebSitesettings ? WebSitesettings.CompanyWebsite : " "}
                </a>
                . On {WebSitesettings ? WebSitesettings.WebsiteName : " "},
                users can compete for real cash in Tournaments and Battles. They
                can encash their winnings via popular options such as Paytm
                Wallet, Amazon Pay, Bank Transfer, Mobile Recharges etc.
              </div>
              <br />
              <div className="footer-text-bold">Our Games</div>
              <br />
              <div className="footer-text">
                {WebSitesettings ? WebSitesettings.WebsiteName : " "} has a
                wide-variety of high-quality, premium HTML5 games. Our games are
                especially compressed and optimised to work on low-end devices,
                uncommon browsers, and patchy internet speeds.
              </div>
              <br />
              <div className="footer-text">
                We have games across several popular categories: Arcade, Action,
                Adventure, Sports &amp; Racing, Strategy, Puzzle &amp; Logic. We
                also have a strong portfolio of multiplayer games such as Ludo,
                Chess, 8 Ball Pool, Carrom, Tic Tac Toe, Archery, Quiz, Chinese
                Checkers and more! Some of our popular titles are: Escape Run,
                Bubble Wipeout, Tower Twist, Cricket , Ludo With Friends. If you
                have any suggestions around new games that we should add or if
                you are a game developer yourself and want to work with us,
                don't hesitate to drop in a line at{" "}
                
                !
              </div>
              </div>
            </div>
          </section>
          <div className="downloadButton">
            <Downloadbutton />
          </div>
        </div>
      </div>
      {/* // <div className='rightContainer'>
            //     <Rightcontainer/>
            // </div> */}
    </>
  );
}
